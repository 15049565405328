import React from 'react';
import './App.css';
import { API, graphqlOperation } from 'aws-amplify';
import gql from 'graphql-tag';
import { compareDesc, format, parseISO } from 'date-fns';
import { nl } from 'date-fns/locale';
import Die from './components/die';

const ALL_ROLLS_QUERY = gql`
  query AllRolls {
    listDiceRolls {
      id
      number1
      number2
      playerName
      thrownAt
    }
  }
`

const CREATE_ROLL_MUTATION = gql`
  mutation RollDice($number1: Int!, $number2: Int!, $playerName: String!) {
    createDiceRoll(input: {number1: $number1, number2: $number2, playerName: $playerName}) {
    id
    thrownAt
    number1
    number2
    playerName
  }
  }
`

const ON_CREATE_ROLL_SUBSCRIPTION = gql`
  subscription OnCreateDiceRoll {
    onCreateDiceRoll {
      id
      playerName
      thrownAt
      number1
      number2
    }
  }
`

async function rollDice(userName: string) {
  const n1 = Math.ceil(Math.random() * 6);
  const n2 = Math.ceil(Math.random() * 6);

  const res = await API.graphql(graphqlOperation(
    CREATE_ROLL_MUTATION,
    {
      number1: n1,
      number2: n2,
      playerName: userName
    }
  ))

  console.log(res);
}

function App() {
  const [rolls, setRolls] = React.useState<any[]>([]);
  const [userName, setUserName] = React.useState('');
  async function fetchDiceRolls() {
    try {
      const result = await API.graphql(graphqlOperation(ALL_ROLLS_QUERY));

      setRolls((result as any).data.listDiceRolls || []);
    } catch (error) {
      console.log(error)
    }
  }

  React.useEffect(() => {
    fetchDiceRolls();

    const subscription = (API.graphql(graphqlOperation(ON_CREATE_ROLL_SUBSCRIPTION)) as any).subscribe({
      next: ({ value }: any) => setRolls(rolls => [...rolls, value.data.onCreateDiceRoll]),
      error: (error: any) => console.warn(error)
    });

    return () => subscription.unsubscribe();
  }, [])
  return (
    <div>
      <h1 style={{ textAlign: 'center' }}>3 mannen - the app</h1>
      <div style={{
        display: 'flex',
        padding: 40
      }}>
        <input style={{ minWidth: 300, marginRight: 20 }} value={userName} onChange={(e) => setUserName(e.target.value)} placeholder="Jouw naam, grote speler..." />
        <button style={{ background: 'green' }} type="button" disabled={!Boolean(userName)} onClick={() => rollDice(userName)}>Lekker spelen! 🎲🎲</button>

      </div><hr />
      <ul style={{ margin: 0, padding: 40 }}>
        {rolls.length > 0 && rolls.sort((a, b) => compareDesc(parseISO(a.thrownAt), parseISO(b.thrownAt))).map((r, i) => (
          <li style={{
            display: 'flex',
            flexWrap: 'wrap',
            marginBottom: '20px',
            padding: '20px',
            border: '1px solid rgba(0,0,0,0.5)',
            borderRadius: '5px'
          }} key={r.id}>
            <p style={{ fontWeight: 'bold', width: '100%' }}>{r.playerName}</p>
            <Die value={r.number1} />
            <Die value={r.number2} /> 
            <br /><br />

            <time style={{ width: '100%', fontStyle: 'italic', fontSize: 14, color: 'grey'}}>{format(parseISO(r.thrownAt), 'kk:mm:ss - EEEE d MMMM yyyy', {
              locale: nl
            })}</time>
            </li>
        ))}
      </ul>
    </div>
  );
}

export default App;
